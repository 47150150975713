.event-item {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
  
  .date {
    display: inline-block;
    width: 110px; /* Set a fixed width based on the longest date */
    text-align: left;
  }
  
  .label {
    margin-left: 5px; /* Optional: Add some spacing between the date and the label */
  }
  